export interface MenuItem {
  label: string;
  url: string;
  children: MenuItem[];
  order: number;
  root?: boolean;
}

export interface LegalMenuItemDTO {
  label: string;
  url: string;
}

export class LegalMenuItem {
  label: string;
  url: string;

  constructor(menuItem: LegalMenuItemDTO) {
    this.label = menuItem.label;
    this.url = menuItem.url;
  }
}
